<template>
  <div class="border-secondary">
    <providers-promo-header />
    <providers-promo-plans class="mt-8 mt-sm-9 pb-9" />
    <providers-promo-faq class="mt-16" />
  </div>
</template>

<script>
  import ProvidersPromoHeader from './ProvidersPromoHeader/Index.vue'
  import ProvidersPromoPlans from './ProvidersPromoPlans/Index.vue'
  import ProvidersPromoFaq from './ProvidersPromoFaq.vue'

  export default {
    name: 'ProvidersPromo',
    components: {
      ProvidersPromoHeader,
      ProvidersPromoPlans,
      ProvidersPromoFaq
    }
  }
</script>
